<script>
	import GlobalVue from '@helper/Global.vue';
	import Gen from '@helper/Gen';

	export default {
		extends: GlobalVue,
		data() {
			return {
				headerCategory: {},
				headerHealth: {},
				filter: {},
				openSearch: false,
				cmp_phone: {},
				cmp_facebook: {},
				cmp_instagram: {},
				cmp_twitter: {},
				cmp_youtube: {},
			}
		},
		watch: {
			'$route.path'() {
				this.openSearch = false
				this.setActiveMenu()
			}
		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.getHeaderCategory()
			this.getHeaderHealth()

			setTimeout(() => {
				$("#primary-menu-trigger").off('click').click(function () {
					$("#primary-menu, #primary-menu-trigger").toggleClass("menu-open");
					$("body").toggleClass("overflow-hidden");
					this.openSearch = false
				})
				this.setActiveMenu()
			}, 1000);

			if ($(window).width() <= 991) {
				$("[data-img-mobile]").each(function () {
					let heroMobile = $(this).attr("data-img-mobile");
					$(this).css("background-image", "url('" + heroMobile + "')");
				});
				// setTimeout(() => {
				//     $("#primary-menu > ul > li.sub-menu").append("<i class='icon-angle-right'></i>");
				//     $("#primary-menu > ul > li").append("<span class='line-effect d-lg-none'>");
				// }, 800);
			}
		},
		methods: {
			setActiveMenu() {
				// $('[href="'+this.$route.path+'"]').parent().addClass('active')
				$(".menu-open").removeClass("menu-open")
				$("body").removeClass("overflow-hidden")
				$("#primary-menu li").removeClass("active")
				setTimeout(() => {
					$("#primary-menu .router-link-active").closest("li").addClass("active")
				}, 100)
			},
			getHeaderCategory() {
				Gen.apirest("/product-list-category", {}, (err, resp) => {
					if (err) console.log(err)
					this.headerCategory = resp.data
					this.cmp_phone = resp.cmp_phone
					this.cmp_facebook = resp.cmp_facebook
					this.cmp_instagram = resp.cmp_instagram
					this.cmp_twitter = resp.cmp_twitter
					this.cmp_youtube = resp.cmp_youtube
				})
			},
			getHeaderHealth() {
				Gen.apirest("/health-list-category", {}, (err, resp) => {
					if (err) console.log(err)
					this.headerHealth = resp.data
				})
			},
			search() {
				if (!this.filter.search) return swal("Please Input Keyword", "", "warning")
				this.$router.push({
					name: 'Search',
					query: {
						search: this.filter.search
					}
				})
				this.openSearch = false
			},
			setFoc() {
				this.openSearch = !this.openSearch;
				if ($("#primary-menu").hasClass('menu-open')) {
					$("#primary-menu, #primary-menu-trigger").toggleClass("menu-open");
					$("body").toggleClass("overflow-hidden");
				}
				setTimeout(() => {
					$(".form-control.search").focus();
				}, 500);
			}
		},
	};
</script>

<template>
	<header id="header" class="" data-sticky-class="not-dark">
		<div id="header-wrap">
			<div class="container clearfix">
				<div id="primary-menu-trigger">
					<i class="trigger">

						<img :src="assets('fo_images','menu.svg')" alt="Menu">
					</i><i class="icon-line-cross menu-close"></i>
				</div>
				<a id="primary-search" class="pm-search-toggle" @click="setFoc()" href="javascript:;">
					<i class="icon-search" v-if="!openSearch"></i>
					<i class="icon-line-cross" v-else></i>
				</a>
				<transition name="customClass" enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
					<div id="search-bar" v-if="openSearch">
						<VForm @resp="search">
							<div class="form-row justify-content-end">
								<div class="col-md-9 col-10">
									<input type="text" v-model="filter.search" class="form-control search" placeholder="Ketik disini ...">
								</div>
								<div class="col-2 col-md-3 text-right text-md-left">
									<button type="submit" class="primary-btn" :class="isMobile ? 'px-2 btn-block':''">
										<span v-if="!isMobile">Cari</span>
										<span v-else class="icon-search"></span>
									</button>
								</div>
							</div>
						</VForm>
					</div>
				</transition>
				<div id="logo">
					<h1 style="color: #fff;font-size: 1px;margin: 0;position:absolute;">Tropicana Slim - Cegah Diabetes Untuk
						Hidup Lebih Lama</h1>
					<router-link :to="{name:'Index'}" class="standard-logo">
						<img :src="assets('fo_images','ts-2019.png')" alt="Tropicana Slim Logo">
					</router-link>
					<router-link :to="{name:'Index'}" class="retina-logo">
						<img :src="assets('fo_images','ts-2019.png')" alt="Tropicana Slim Logo">
					</router-link>
				</div>
				<nav id="primary-menu" class="not-dark">
					<ul>
						<li class="active">
							<router-link :to="{name:'Products'}">
								<div>{{web.mn_product}}</div>
							</router-link>
							<ul>
								<li class="d-lg-none">
									<router-link :to="{name:'Products'}">
										<div>{{web.lbl_semua_produk}}</div>
									</router-link>
								</li>
								<li v-for="(v1,k1) in headerCategory" :key="k1">
									<router-link :to="{name:'ProductsList', params:{slug:v1.mpc_slug}}">
										<div v-html="v1.mpc_name"></div>
									</router-link>
								</li>
							</ul>
							<i class="icon-angle-right d-lg-none"></i>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<router-link :to="{name:'HealthInfo'}">
								<div>{{web.mn_info}}</div>
							</router-link>
							<ul>
								<li class="d-lg-none">
									<router-link :to="{name:'HealthInfo'}">
										<div>{{web.lbl_semua_info}}</div>
									</router-link>
								</li>
								<li v-for="(v2,k2) in headerHealth" :key="k2">
									<router-link :to="{name:'HealthInfoDetail', params:{slug:v2.mht_slug}}"
										v-if="v2.mht_have_subcategory=='Y'">
										<div v-html="v2.mht_title"></div>
									</router-link>
									<router-link :to="{name:'HealthInfoDetailArticle', params:{slug:v2.mht_slug,sub:'article'}}" v-else>
										<div v-html="v2.mht_title"></div>
									</router-link>
								</li>
							</ul>
							<i class="icon-angle-right d-lg-none"></i>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<router-link :to="{name:'Resep'}">
								<div>{{web.mn_resep}}</div>
							</router-link>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<router-link :to="{name:'Event'}">
								<div>{{web.mn_event}}</div>
							</router-link>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<a href="https://www.nutrimart.co.id/brands/tropicana-slim.html" target="_blank">
								<div>{{web.mn_shop}}</div>
							</a>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<router-link :to="{name:'TanyaDoctor'}">
								<div>{{web.mn_tanya}}</div>
							</router-link>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li>
							<a target="_blank" v-if="cmp_phone" :href="'https://wa.me/'+cmp_phone.aws_val">
								<div>{{web.mn_contact}}</div>
							</a>
							<span class="line-effect d-lg-none"></span>
						</li>
						<li class="no-hover d-lg-none d-xl-block ml-lg-5">
							<div class="social-icons">
								<!-- <a href="https://wa.me/6285691601254" target="_blank"
                                    class="social-icon si-small si-rounded si-bordered si-whatsapp" title="WhatsApp">
                                    <i class="icon-whatsapp"></i>
                                    <i class="icon-whatsapp"></i>
                                </a> -->
								<a v-if="cmp_facebook&&cmp_facebook.aws_is_active=='Y'" :href="cmp_facebook.aws_val" target="_blank"
									class="social-icon si-small si-rounded si-bordered si-facebook" title="Facebook">
									<i class="icon-facebook"></i>
									<i class="icon-facebook"></i>
								</a>
								<a v-if="cmp_instagram&&cmp_instagram.aws_is_active=='Y'" :href="cmp_instagram.aws_val" target="_blank"
									class="social-icon si-small si-bordered  si-rounded si-instagram" title="Instagram">
									<i class="icon-instagram"></i>
									<i class="icon-instagram"></i>
								</a>
								<a v-if="cmp_twitter&&cmp_twitter.aws_is_active=='Y'" :href="cmp_twitter.aws_val" target="_blank"
									class="social-icon si-small si-bordered  si-rounded si-twitter" title="Twitter">
									<i class="icon-twitter"></i>
									<i class="icon-twitter"></i>
								</a>
								<a v-if="cmp_youtube&&cmp_youtube.aws_is_active=='Y'" :href="cmp_youtube.aws_val" target="_blank"
									class="social-icon si-small si-bordered  si-rounded si-youtube" title="YouTube">
									<i class="icon-youtube"></i>
									<i class="icon-youtube"></i>
								</a>
							</div>
						</li>
						<li class="on-right no-hover d-none d-lg-block">
							<a class="pm-search-toggle" @click="setFoc()" href="javascript:;">
								<div>
									<i class="icon-search" v-if="!openSearch"></i>
									<i class="icon-line-cross" v-else></i>
								</div>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>